import {useCaseData} from '../shared/Hooks/Case';
import {useHeaderData} from '../shared/Hooks/Headers';

export const useData = () => {
  const caseData = useCaseData().cersanit;
  const header = useHeaderData();

  return {
    seo: {
      title: 'Case study',
    },
    cases: caseData,
    header,
  };
};
