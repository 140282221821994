import React from 'react';
import Case from '../sections/Case';
import {useData} from '../data/case';

const Index = () => {
  const data = useData();

  return (
    <Case data={data}/>
  );
};

export default Index;
